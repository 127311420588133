/* global newrelic: false */
const global = require('../../../global');

function TrackNewrelic({ newrelicTransactionName }) {
  if (global.newrelic) {
    newrelic.setPageViewName(newrelicTransactionName);
  }

  return null;
}

module.exports = TrackNewrelic;
