/* global freya: false */
const global = require('../../../global');

function MeliMessages({ platformId, siteId, mainDomain }) {
  if (global.freya && freya.messages && platformId) {
    // Deremate Messages
    freya.messages.init({
      url: `http://www.${mainDomain}/org-img/MOVEDR2/${global.location.hash.substring(11)}_${siteId}.json`,
      hash: '#MOVE_FROM',
      callback: 'moveDRCallback',
    });

    // Registration Messages
    // Set url in server
    freya.messages.init({
      url: `https://registration.${mainDomain}/registration/welcome/message`,
      hash: '#WM',
    });

    // Set url in server
    freya.messages.init({
      url: `https://registration.${mainDomain}/registration/confirmation/message`,
      hash: '#CM',
    });

    // Registration Messages from Referral Program
    // Set url in server
    freya.messages.init({
      url: `https://referral.${mainDomain}/welcome/registered`,
      hash: '#WRPM',
    });

    // Association Messages from Referral Program
    // Set url in server
    freya.messages.init({
      url: `https://referral.${mainDomain}/welcome/associated`,
      hash: '#WAPM',
    });
  }

  return null;
}

module.exports = MeliMessages;
