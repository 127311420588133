const React = require('react');
const Script = require('nordic/script');
const PropTypes = require('prop-types');

let gtmScript = '';
const GtmScript = (props) => {

  const gtmConfig = {
    id: 'GTM-WBL6KGR',
  };
  if (!gtmConfig.id) {
    return null;
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (props.type === 'header' && gtmConfig.id) {
    return (
      <Script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmConfig.id}');
      `}
      </Script>
);
  }
  // eslint-disable-next-line react/destructuring-assignment
  if (props.type === 'body' && gtmConfig.id) {
    gtmScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmConfig.id}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`;
    gtmScript = <div dangerouslySetInnerHTML={{ __html: gtmScript }} />;
  }
  return (gtmScript);
};

GtmScript.defaultProps = {
  type: '',
};

GtmScript.propTypes = {
  type: PropTypes.string,
};

module.exports = GtmScript;
