// We forked this code from https://github.com/kolodny/weak-key/
// This is because the original dependency uses core-js@2 which
// is not compatible with nordic 7.
const map = new WeakMap();
let index = 0;

const weakKey = (obj) => {
  let key = map.get(obj);

  if (!key) {
    index += 1;
    key = `weak-key-${index}`;
    map.set(obj, key);
  }

  return key;
};

module.exports = weakKey;
