const React = require('react');
const Script = require('nordic/script');
const PropTypes = require('prop-types');

const Recaptcha = (props) => {
  const { siteKey } = props;
  return (
    <>
      <Script>
        {`
          var recaptchaScript = document.createElement('script');
          recaptchaScript.src = 'https://www.google.com/recaptcha/enterprise.js?render=${siteKey}';
          document.head.appendChild(recaptchaScript);
        `}
      </Script>
      <Script>
        {`
          // How this code snippet works:
          // This logic overwrites the default behavior of 'grecaptcha.ready()' to
          // ensure that it can be safely called at any time. When 'grecaptcha.ready()'
          // is called before reCAPTCHA is loaded, the callback function that is passed
          // by 'grecaptcha.ready()' is enqueued for execution after reCAPTCHA is
          // loaded.

          if(typeof grecaptcha === 'undefined') {
            grecaptcha = {};
          }
          grecaptcha.ready = function(cb){
            if(typeof grecaptcha.enterprise === 'undefined') {

              // window.__grecaptcha_cfg is a global variable that stores reCAPTCHA's
              // configuration. By default, any functions listed in its 'fns' property
              // are automatically executed when reCAPTCHA loads.
              const c = '___grecaptcha_cfg';
              window[c] = window[c] || {};
              (window[c]['fns'] = window[c]['fns']||[]).push(cb);

            } else {
              // reCAPTCHA is loaded and callback is called immediately
              cb();
            }
          }
        `}
      </Script>
    </>
  );
};

Recaptcha.propTypes = {
  siteKey: PropTypes.string,
};

module.exports = Recaptcha;
