/* eslint-env browser, node */
const globalDefault = () => {
  if (typeof window === 'object') {
    return window;
  }
  return global;
};

// TODO: use an environment variable to know where we are so webpack is able to remove the unused path.
module.exports = globalDefault();
