const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames'); // eslint-disable-line import/no-extraneous-dependencies
const MelidataTrack = require('nordic/melidata/melidata-track');
const Script = require('nordic/script');
const Style = require('nordic/style');
const Hotjar = require('nordic/hotjar');
// @TODO the restClient function uses native javascript code internally, which throws an error
// when serialized using nordic/serialize-javascript. We should avoid passing the restclient
// instance from the server and we should use a custom endpont instead for traceability purposes.
const serialize = require('fast-safe-stringify');
const { AndesProvider } = require('@andes/context');
const MeliMessages = require('../../commons/meli-messages');
const TrackNewrelic = require('../../commons/track-newrelic');
const Header = require('../../commons/header');
const GtmScript = require('../../commons/gtm');
const Recaptcha = require('../../commons/recaptcha');
const { removeAccessTokensFromUrl } = require('../../../utils');

const Wrapper = ({ children, ...props }) => {
  const {
    // wrapper
    innerClassName, pageId, pageName, listId,
    // commons
    platformId, siteId, deviceType, meliLab, userAgent,
    // locals
    melidata, atf, hotjar, experiments, newrelicTransactionName,
    currentSearch, preloadRecommendationsImgs,
    // request
    isLegacyBrowser, lowEnd, url, user, userId, currentUser, platform, device, locale,
    csrfToken, isLab, isFirstVisit, type, translations,
    // config
    mainDomain, baseDomain, defaultTitle, home, baseURL,
    // internal
    restClient, criticalPath, isServerSide,
    // secrets
    recaptchaEntKey,
  } = props;
  const sanitizedUrl = removeAccessTokensFromUrl(url);
  const browserType = isLegacyBrowser ? '.legacy' : '';
  const serializedProps = {
    innerClassName, pageId, pageName, listId,
    platformId, siteId, deviceType, meliLab, userAgent,
    melidata, atf, hotjar, experiments, newrelicTransactionName,
    currentSearch, preloadRecommendationsImgs,
    isLegacyBrowser, lowEnd, url: sanitizedUrl, user, userId, currentUser, platform,
    csrfToken, isLab, isFirstVisit, type, translations,
    mainDomain, baseDomain, defaultTitle, home, baseURL,
    restClient, criticalPath, isServerSide, device, locale,
    recaptchaEntKey,
  };
  return (
    <AndesProvider
      csrfToken={csrfToken}
      device={device}
      locale={locale}
    >
      <div className={classNames(innerClassName, { 'low-end': lowEnd })}>
        {melidata && (
        <MelidataTrack
          path={melidata.path}
          type={melidata.type}
          event_data={melidata.event_data}
        />
        )}
        <GtmScript type="header" />
        <Recaptcha siteKey={recaptchaEntKey} />
        <Header
          {...atf.header}
          user={user}
          url={sanitizedUrl}
          platform={platformId}
          siteId={siteId}
          preloadRecommendationsImgs={preloadRecommendationsImgs}
        />
        <Style href={`${pageName}.${deviceType}.css`} critical={deviceType === 'mobile'} />
        <Script>{`window.ML_PRELOADED_STATE = ${serialize(serializedProps)};`}</Script>
        <Script src={`vendor${browserType}.js`} />
        <Script src={`${pageName}.${deviceType}${browserType}.js`} />
        <Hotjar {...hotjar} deviceType={deviceType} />
        <MeliMessages platform={platformId} siteId={siteId} mainDomain={mainDomain} />
        {/* Wrapper children components */}
        {children}
        {/* Wrapper children components render end */}
        <TrackNewrelic newrelicTransactionName={newrelicTransactionName} />
        <GtmScript type="body" />
      </div>
    </AndesProvider>
  );
};

Wrapper.propTypes = {
  innerClassName: PropTypes.string,
  userAgent: PropTypes.string.isRequired,
  pageId: PropTypes.string,
  pageName: PropTypes.string,
  platformId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  deviceType: PropTypes.oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  url: PropTypes.string.isRequired,
  mainDomain: PropTypes.string.isRequired,
  newrelicTransactionName: PropTypes.string.isRequired,
  baseDomain: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  currentSearch: PropTypes.object,
  csrfToken: PropTypes.string.isRequired,
  restClient: PropTypes.object.isRequired,
  cdn: PropTypes.object,
  hotjar: PropTypes.object, // will be required
  atf: PropTypes.object, // will be required
  melidata: PropTypes.object, // will be required
  children: PropTypes.node, // will be required
  isLegacyBrowser: PropTypes.bool,
  lowEnd: PropTypes.bool,
  preloadRecommendationsImgs: PropTypes.array,
  device: PropTypes.object,
  locale: PropTypes.string,
};

Wrapper.defaultProps = {
  innerClassName: 'home',
  pageId: 'HOME',
  pageName: 'home',
  currentSearch: {},
  lowEnd: true,
  isLegacyBrowser: true,
  preloadRecommendationsImgs: null,
  hotjar: null,
  atf: null,
  melidata: null,
  children: null,
  cdn: {},
};

module.exports = Wrapper;
