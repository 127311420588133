/* eslint-env browser */

const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const React = require('react');
const hydrate = require('nordic/hydrate');
const restClient = require('./services/rest-client');

module.exports = function startApp(Component) {
  const preloadedState = window.ML_PRELOADED_STATE;

  const i18n = new I18n({ translations: preloadedState.translations });
  const restClientWithToken = restClient({
    deviceType: preloadedState.deviceType,
  });

  hydrate(
    <I18nProvider i18n={i18n}>
      <Component {...preloadedState} restClient={restClientWithToken} />
    </I18nProvider>,
    document.getElementById('root-app')
  );
};
