const React = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const key = require('../../../../lib/weak-key');

function Header({
  title,
  description,
  googleVerification,
  site_name: siteName,
  siteId,
  preloadRecommendationsImgs,
  url,
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content="https://www.portalinmobiliario.com/images/logoiso200x200.png" />
      {googleVerification ? <meta name="google-site-verification" content={googleVerification} /> : null}
      <link rel="canonical" href={url} />
      <link
        title={siteName}
        rel="search"
        type="application/opensearchdescription+xml"
        href={`https://http2.mlstatic.com/static/org-img/mercadosocio/opensearch/opensearch-description-${siteId.toLowerCase()}.xml`}
      />
      <link rel="alternate" href="ios-app://463624852/meli/home" />
      <link rel="alternate" href="android-app://com.mercadolibre/meli/home" />
      <link rel="preconnect" href="https://adservice.google.com" />
      <link rel="preconnect" href="https://www.googletagservices.com" />
      <link rel="preconnect" href="https://securepubads.g.doubleclick.net" />
      <link rel="preconnect" href="https://tpc.googlesyndication.com" />
      <link rel="preconnect" href="https://pagead2.googlesyndication.com" />
      <link rel="preconnect" href="https://data.mercadolibre.com" />
      {preloadRecommendationsImgs &&
        preloadRecommendationsImgs.map((imgUrl) => (
          <link key={key({ rel: 'preload', as: 'image', url: imgUrl })} rel="preload" href={imgUrl} as="image" />
        ))}
    </Head>
  );
}

Header.defaultProps = {
  googleVerification: null,
  preloadRecommendationsImgs: null,
};

Header.propTypes = {
  description: PropTypes.string.isRequired,
  googleVerification: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  site_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  preloadRecommendationsImgs: PropTypes.array,
};

module.exports = Header;
